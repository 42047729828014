import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import AdSense from "react-adsense"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Article } from "../../style/article"
import { Button } from "../../style/generic"

const CalisthenicsBuildMusclePage = () => {
  const data = useStaticQuery(graphql`
    query MedicineBallQuery {
      headerImg: file(base: { eq: "medicine-ball.jpg" }) {
        publicURL
      }
      sectionImg: file(base: { eq: "gymnast.jpg" }) {
        publicURL
      }
    }
  `)
  return (
    <Layout>
      <Article>
        <SEO
          title="How To Build Muscle With Calisthenics"
          description="It is possible to build muscle using your bodyweight! Calisthenics is a beneficial exercise routine that uses bodyweight resistance to build muscle. Since the exercises use your body weight, it offers a convenient fitness regime that you can do anywhere."
        />
        <header style={{ backgroundImage: `url(${data.headerImg.publicURL})` }}>
          <h1>How To Build Muscle With Calisthenics</h1>
        </header>
        <AdSense.Google client="ca-pub-4782119177742028" slot="6965831007" />
        <section>
          <p className="intro">
            Often, the intent to build muscle is associated with lifting heavy
            weights. Myth has it that in order to bulk up, you must spend hours
            lifting weights in the gym. This, however, is a common
            misconception. Can you build muscle just from using your body
            weight? The answer is:{" "}
            <span className="highlight">Yes, of course you can</span>
          </p>
          <p className="intro">
            You have definitely seen it all around you. The guy at the gym who
            can lift most of their upper body above the pull-up bar and the one
            online who can do a headstand using only two fingers all have one
            thing in common. Calisthenics (usually).{" "}
          </p>
          <h2>What is calisthenics?</h2>
          <p>
            Tee Major, a U.S. military fitness instructor and author of{" "}
            <a
              href="https://www.amazon.com/Urban-Calisthenics-Progressive-Workouts-Anywhere/dp/1465473513"
              target="_blank"
              rel="nofollow"
            >
              Urban Calisthenics
            </a>
            , defines calisthenics as an art form utilizing only one's body
            weight to maximize athletic power and human ability. Fundamentally,
            the goal of calisthenics is to master control over lifting while
            moving your body in space.{" "}
          </p>
          <p>
            This form of exercise is the simplest and has been around for a very
            long time. Calisthenics is a term derived from the Greek term{" "}
            <i>kallos</i> meaning beauty and <i>sthenos</i>, which means
            strength. Historically, the practice is recorded to have been used
            in Ancient Greece by the armies of Alexander the Great and the
            Spartans.
          </p>
          <p>
            Calisthenics was later used as a means of promoting health and with
            time evolved into a training method that is very similar to
            gymnastics. If you want to read more on calisthenics and some of its
            benefits you can read about it{" "}
            <Link to="/thoughts/calisthenics-in-2021/">here</Link>.
          </p>
          <h2>Are calisthenics effective?</h2>
          <p>
            <a
              href="https://www.researchgate.net/publication/317321468_The_effects_of_a_calisthenics_training_intervention_on_posture_strength_and_body_composition"
              target="_blank"
            >
              Research
            </a>{" "}
            shows that calisthenics is an effective physical activity that
            improves strength endurance, postural sway, and significantly
            reduces body fat mass. The brain doesn't differentiate between how
            resistance is being applied but that there is resistance that it
            needs to overcome. It then helps the body produce force to overcome
            the resistance with your muscles.{" "}
          </p>
          <div className="split-section">
            <div>
              <h2>How can calisthenics build muscle?</h2>
              <p>
                In order to build muscle, the body needs favorable conditions,
                also known as hypertrophy. Muscle hypertrophy is defined as the
                enlargement of the skeletal muscle fibers as the body reacts to
                overcoming force caused by high levels of tension. You need to
                create conditions for hypertrophy to occur by providing high
                levels of force and tension and then forcing your muscles to
                overcome the resistance being applied to them. Your body then
                needs to adapt to the new stress you're placing on it.{" "}
              </p>
              <p>
                Once the mind establishes there is resistance, it will produce
                force through your muscles to overcome it.{" "}
              </p>
            </div>
            <img src={data.sectionImg.publicURL} alt={`gymnast hanging`} />
          </div>
          <h2>Calisthenic Equipment</h2>
          <p>
            The fantastic thing about calisthenics is that you do not need any
            complex equipment to get started, and neither do you need a monthly
            subscription or a gym membership. The basic equipment you need is
            available at the park for free. If you are serious about practicing
            calisthenics at home, some good to have things are:
          </p>
          <ul>
            <li>
              <p>A pull-up bar </p>
            </li>
            <li>
              <p>Paralletes</p>
            </li>
            <li>
              <p>Resistance bands </p>
            </li>
          </ul>
          {/*<p>
            Checkout out our <Link to="/reviews/">reviews</Link> section for
            more information on the best equipment to get started, or to take
            your exercise to the next level.
          </p>*/}
          <h2>Calisthenics workout for beginners</h2>
          <p>
            It is crucial to test out a couple of beginner exercises, learn the
            right form, and then put them in a complete routine. Remember, it is
            better to do the exercise with good form a few times than doing it
            many times with a bad form. Here are some great calisthenics
            workouts for beginners:
          </p>
          <h3>Chin-ups</h3>
          <p>
            Stand with a pull-up or exercise bar right in front of you. Hold the
            bar from underneath and ensure your arms close tightly at a
            shoulder-width grip. Using your biceps, pull yourself up to bring
            your head up and over the bar.
          </p>
          <h3>Plank</h3>
          <p>
            Face down with your arms and toes on the ground. Ensure your elbows
            are directly under your shoulders and your forearms are on the floor
            facing forward. Keep your back straight, head relaxed, and engage
            your core and glutes. Hold the position without sagging your hips
            for 30-45 seconds.
          </p>
          <h3>Pushups</h3>
          <p>
            Get on your knees and carefully place your hands shoulder-width
            apart underneath you. Stretch your legs out while holding your body
            in a plank position. Ensure your backside doesn't stick out or sag.
            Slowly lower your body by bending at the elbows until your chest
            almost touches the floor. Pause for a few seconds, then push back
            up. Keep your core engaged through the entire movement.
          </p>
          <h3>Squats</h3>
          <p>
            Stand with your feet parallel and shoulder-width apart. Lower your
            body into a sitting position by bending your knees and pushing your
            hips back. Go as far down as you can, then come back to a standing
            position. Ensure you keep your back straight and head up throughout
            the exercise.{" "}
          </p>
          <h3>Dips</h3>
          <p>
            Stand inside a dip bar and, using your shoulders and arms, lift
            yourself off the floor. Lower your body by bending at the elbows and
            use your triceps to move up and down for a few repetitions. If you
            don't have a dip bar, use a bench or exercise ball. Keep your feet
            on the ground, bend your knees at a 90-degree angle, and then lift
            and lower your body.
          </p>
          <h3>Lunges</h3>
          <p>
            While standing with your feet shoulder-width apart, step forward
            with the left leg and bend it at the knee. Lower your knee down
            until it almost touches the ground and pause for 20 seconds. Using
            your heel, lift yourself up and push into another lunge using your
            right leg.{" "}
          </p>
          <h2>Calisthenics, how many times a week should you train?</h2>
          <p>
            Often, the frequency of a calisthenics practice varies between
            individuals based on the difficulty and intensity of the exercises.
            However, three days a week is an excellent place to start. It is
            advisable to give your muscles at least 48 hours to rest. Over time,
            you may increase the frequency to five days a week or more.
          </p>
          <h2>The benefits of calisthenics</h2>
          <p>
            Frequently practicing calisthenics is not only beneficial for the
            body but also your lifestyle.
          </p>
          <ul>
            <li>
              <p>
                <strong>You can practice anywhere:</strong> Since calisthenics
                exercises utilize body weight for resistance, you can practice
                in the comfort of your home, at the park, or in a hotel room
                when on holiday.{" "}
              </p>
            </li>
            <li>
              <p>
                <strong>It is great for beginners:</strong> Most calisthenics
                exercises are simple and commonly practiced. When starting out,
                you need to focus on the correct form before progressing to
                higher resistance exercises.
              </p>
            </li>
            <li>
              <p>
                <strong>It utilizes the entire body:</strong> Unlike traditional
                weight lifting, calisthenics exercises are designed to
                incorporate the whole body into the movement rather than just a
                single part.
              </p>
            </li>
            <li>
              <p>
                <strong>It helps build strength:</strong> Calisthenic exercise
                allows your body to build strength sustainably. In the long run,
                your entire body grows stronger too.{" "}
              </p>
            </li>
          </ul>
        </section>
        <div>
          <h2>Summary</h2>
          <p>
            As you begin to establish a calisthenic practice, focus on basic
            beginner exercises, and work on achieving a great form. Build on it
            and over time as your body starts to acquire strength, progress to
            complex, challenging exercises with equipment as demonstrated in our
            article{" "}
            <Link to="/thoughts/top-5-calisthenics-home-gym-equipment/">
              Top 5 Equipment To Build a Calisthenics Home Gym
            </Link>
            . Remember, exercise isn't everything, and healthy eating habits go
            a long way towards helping you achieve your fitness goals.
          </p>
        </div>
        <div className="read-more">
          <h2>Want More?</h2>
          <span>Article</span>
          <h3>Home Workout vs Gym: Which Option Is Better for You?</h3>
          <p>
            when it comes to exercise we all know we need to more of it, but
            finding the motivation to do it is a challenge in itself. And we can
            quite…
          </p>
          <Link to={`/thoughts/home-workout-vs-gym/`}>
            <Button>Read</Button>
          </Link>
        </div>
      </Article>
    </Layout>
  )
}

export default CalisthenicsBuildMusclePage
